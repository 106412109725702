import styles from '../styles/components/full-width-tile.module.scss';
import Link from 'next/link';
import rmrLogo from '../public/images/roy-morgan-logo.png';
import ClickThroughLink from './click-through-link';
import type { Image as ImageType } from '../types/blocks/image';
import type { Attributes } from '../types/blocks/attributes';
import Image from 'next/image';

interface Props {
  title?: string;
  featuredImage?: {
    node: {
      sourceUrl: string;
      mediaDetails: {
        height: number;
        width: number;
      };
    };
  };
  tileImage?: ImageType;
  slug?: string;
  link_text?: string;
  excerpt?: string;
  attributes?: Attributes;
  tileType?: string;
  image_right?: boolean;
  openInNewTab?: boolean | null;
  showLink?: boolean;
  isButton?: boolean;
  linkPositionTop?: boolean;
}

export default function FullWidthTile({
  title,
  featuredImage,
  tileImage,
  slug,
  link_text,
  excerpt,
  attributes,
  tileType,
  image_right,
  openInNewTab,
  showLink = true,
  isButton = false,
  linkPositionTop = false,
}: Props): JSX.Element {
  let image = rmrLogo;

  if (featuredImage?.node?.sourceUrl) {
    image = {
      src: featuredImage?.node?.sourceUrl,
      height: featuredImage?.node?.mediaDetails?.height,
      width: featuredImage?.node?.mediaDetails?.width,
    };
  } else if (tileImage?.sourceUrl) {
    image = {
      src: tileImage?.sourceUrl,
      height: parseInt(tileImage?.mediaDetails?.height),
      width: parseInt(tileImage?.mediaDetails?.width),
    };
  }

  return (
    <div
      id={attributes?.anchor}
      className={`${styles.full_width_tile__row} ${
        image_right ? styles.image_right : ''
      }`}
    >
      {!image_right && (
        <div className={styles.full_width_tile__image_wrapper}>
          <Link
            href={slug != undefined ? slug : '/'}
            target={openInNewTab ? '_blank' : '_self'}
            className={styles.full_width_tile__image_link}
          >
            <Image
              src={image.src}
              alt="RMR Logo"
              fill={true}
              style={{
                objectFit: 'cover',
              }}
            />
          </Link>
        </div>
      )}

      <div className={styles.full_width_tile__col_right}>
        {tileType && <h6>{tileType}</h6>}
        <div className={styles.full_width_tile__title_wrapper}>
          <Link
            href={slug != undefined ? slug : '/'}
            target={openInNewTab ? '_blank' : '_self'}
          >
            <h3>{title}</h3>
          </Link>
          {linkPositionTop && showLink && (
            <div className={styles.full_width_tile__clickthrough_link}>
              <ClickThroughLink
                text={
                  link_text !== undefined && link_text != ''
                    ? link_text
                    : 'Learn more'
                }
                link={slug != undefined ? slug : '/'}
                target={openInNewTab ? '_blank' : '_self'}
                isButton={isButton}
              />
            </div>
          )}
        </div>
        <div
          className={styles.full_width_tile__excerpt}
          dangerouslySetInnerHTML={{
            __html: excerpt || '',
          }}
        />
        {!linkPositionTop && showLink && (
          <div>
            <ClickThroughLink
              text={
                link_text !== undefined && link_text != ''
                  ? link_text
                  : 'Learn more'
              }
              link={slug != undefined ? slug : '/'}
              target={openInNewTab ? '_blank' : '_self'}
              isButton={isButton}
            />
          </div>
        )}
      </div>

      {image_right && (
        <div className={styles.full_width_tile__image_wrapper}>
          <Link
            href={slug != undefined ? slug : '/'}
            className={styles.full_width_tile__image_link}
          >
            <Image
              src={image.src}
              alt="RMR Logo"
              fill={true}
              style={{
                objectFit: 'cover',
              }}
            />
          </Link>
        </div>
      )}
    </div>
  );
}
