import styles from '../../styles/components/blocks/accordion.module.scss';
import type { AccordionItem } from '../../types/blocks/accordion-item';
import type { Attributes } from '../../types/blocks/attributes';
import openIcon from '../../public/images/icons/accordion-open.svg';
import closeIcon from '../../public/images/icons/accordion-close.svg';
import Collapsible from 'react-collapsible';

interface Props {
  title?: string;
  items?: Array<AccordionItem>;
  attributes?: Attributes;
  narrow?: Boolean;
}

export default function Accordion({ title, items, attributes, narrow }: Props): JSX.Element {
  const AccordionSection = function (item: AccordionItem, key: number): JSX.Element {
    return (
      <Collapsible
        key={key}
        trigger={
          <div id={item.anchor}>
            <div className={styles.accordion__item_header__text}>{item.header}</div>
            <div className={styles.accordion__item_header__icon}>
              <img src={openIcon.src} alt="Open Icon" />
            </div>
          </div>
        }
        triggerWhenOpen={
          <div id={item.anchor}>
            <div className={styles.accordion__item_header__text}>{item.header}</div>
            <div className={styles.accordion__item_header__icon}>
              <img src={closeIcon.src} alt="Close Icon" />
            </div>
          </div>
        }
        className={styles.accordion__item_wrapper}
        openedClassName={styles.accordion__item_wrapper}
        triggerClassName={styles.accordion__item_header}
        triggerOpenedClassName={styles.accordion__item_header__opened}
        contentInnerClassName={styles.accordion_item_content}
      >
        <div dangerouslySetInnerHTML={{ __html: item.content }} />
      </Collapsible>
    );
  };

  return (
    <div id={attributes?.anchor} className="container">
      <div
        className={`
        ${styles.accordion__wrapper}
        ${narrow ? styles.narrow : ''}
      `}
      >
        {title && <div className={styles.accordion__title}>{title}</div>}
        {items?.map(AccordionSection)}
      </div>
    </div>
  );
}
