import styles from '../../styles/components/blocks/page-text.module.scss';
import type { Attributes } from '../../types/blocks/attributes';

interface Props {
  introduction?: string;
  content?: string;
  attributes?: Attributes;
}

export default function PageText({
  introduction,
  content,
  attributes
}: Props): JSX.Element {
  return (
    <div id={attributes?.anchor} className="container">
      <div className={styles.page_text__wrapper}>
        {introduction && 
          <div 
            className={styles.page_text__intro}
            dangerouslySetInnerHTML={{ __html: introduction }}
          />
        }
        {content && 
          <div 
            className={styles.page_text__content}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        }
      </div>
    </div>
  );
}
