import styles from '../../styles/components/blocks/timeline.module.scss';
import type { TimelineSection, TimelineFootnote } from '../../types/blocks/timeline';
import type { Attributes } from '../../types/blocks/attributes';
import Container from '../container';

interface Props {
  title?: string;
  sections?: Array<TimelineSection>
  footnotes?: Array<TimelineFootnote>
  attributes?: Attributes;
}

export default function Timeline({
  title,
  sections,
  footnotes,
  attributes
}: Props): JSX.Element {

  const Section = function(
    section: TimelineSection, 
    key: number): JSX.Element {
      return (
        <div key={key} className={styles.timeline__section_wrapper}>
          {section && section.image && (
            <div className={styles.timeline__section_image}>
              <img src={section.image?.sourceUrl} alt={section.title}/>
            </div>
          )}
          <hr className={styles.timeline__section_divider} />
          <div className={styles.timeline__section_title}>
            {section.title}
          </div>
          <div 
            className={styles.timeline__section_excerpt}
            dangerouslySetInnerHTML={{ __html: section.excerpt }} 
          />
        </div>
     );
  };

  const Footnote = function(
    footnote: TimelineFootnote,
    key: number): JSX.Element {
      return (
        <p key={key}>{footnote.footnote}</p>
      );
  };

  return (
    <Container>
      <div id={attributes?.anchor} className={styles.timeline__wrapper}>
        {title && 
          <div className={styles.timeline__title}>
            {title}
          </div>
        }
        <div className={styles.timeline__sections_wrapper}>
          {sections?.map(Section)}
        </div>
        <div className={styles.timeline__footnotes_wrapper}>
          {footnotes?.map(Footnote)}
        </div>
      </div>
    </Container>
  );
}
