import type { Attributes } from '../../types/blocks/attributes';

interface Props {
  title?: string;
  target?: string;
  url?: string;
}

export default function Testimonial({
  title,
  target,
  url,
}: Props): JSX.Element {

  return (
	<div className='container'>
    	<a className="btn" href={url} target={target}>{title}</a>
    </div>
  );
}
