import { Block } from '../types/api';
import { MenuItem } from '../types/blocks/menu-item';

export function retrieveMenuItemsFromBlocks(
  blocks: Block[] | undefined
): MenuItem[] {
  if (!blocks) {
    return [];
  }

  const items: MenuItem[] = [];

  blocks.forEach((block) => {
    switch (block.name) {
      case 'acf/offices':
        block.Offices?.offices.officesGroup.forEach((office) => {
          if (!office.officesGroupSidebarTitle) {
            return;
          }

          const anchor = convertTitleToAnchor(office.officesGroupSidebarTitle);

          items.push({
            link: {
              url: `#${anchor}`,
              title: office.officesGroupSidebarTitle,
              isAnchor: true,
            },
          });
        });
        break;
    }

    if (block.attributes?.anchor && block.attributes?.sidebarTitle) {
      items.push({
        link: {
          url: `#${block.attributes.anchor}`,
          title: block.attributes?.sidebarTitle,
          isAnchor: true,
        },
      });
    }
  });

  return items;
}

export function convertTitleToAnchor(title: string) {
  return title.replace(
    /(?=((?:[\!\$&'\(\)\*\+,;=a-zA-Z0-9\-._~:@\/?]|%[0-9a-fA-F]{2})*))\1./g,
    '$1-'
  );
}
