import styles from '../styles/components/related-products.module.scss';
import Link from 'next/link';
import producPlaceholder from '../public/images/placeholders/product-placeholder.png';
import Image from 'next/image';
import arrowRight from "../public/images/arrow-right.svg";
import CickThroughLink from './click-through-link'

interface Props {
  image?: string;
  title?: string;
  excerpt: string;
  slug?: string;
  category?: string;
  prefixSlug?: string;
}

export default function RelatedProduct({
  image,
  title,
  excerpt,
  slug,
  category,
  prefixSlug
}: Props): JSX.Element {
  let productImage = image != null ? image : producPlaceholder.src;
  let productsSlug = typeof prefixSlug === 'undefined' ? "/products-and-tools/" : prefixSlug;
  return (
    <div className={styles.related_product__tile}>   
      <Link
        href={productsSlug + slug}
        className={styles.related_product__tile_title_link}>

        <img 
          className={styles.related_product__tile_image} 
          src={productImage} 
          alt={title} 
        />

      </Link>
      <div className={styles.related_product__tile_content_wrapper}>
        <div className={styles.related_product__tile_content_cateogory}>{category ? category : "PRODUCT"}</div>
        <Link
          href={productsSlug + slug}
          className={styles.related_product__tile_title_link}>

          <h3 className={styles.related_product__tile_title}>{title}</h3>

        </Link>

        <Link
          href={productsSlug + slug}
          className={styles.related_product__tile_excerpt_link}>

          <div className={styles.related_product__tile_excerpt} dangerouslySetInnerHTML={{ __html: excerpt }}></div>

        </Link>
        <div className={styles.related_product__clickthough_link}>
          <CickThroughLink 
            text="Learn more" 
            link={productsSlug + slug}
          />
        </div>
      </div>
    </div>
  );
}
