import styles from '../../styles/components/blocks/block-quote.module.scss';
import type { Attributes } from '../../types/blocks/attributes';
import blockQuoteIcon from '../../public/images/icons/block-quote.svg';
import Image from 'next/image';

interface Props {
  content?: string;
  attributes?: Attributes;
}

export default function Testimonial({
  content,
  attributes
}: Props): JSX.Element {

  return (
    <div id={attributes?.anchor} className={styles.block_quote__wrapper}>
      <div id={attributes?.anchor} className={styles.block_quote__quote_wrapper}>
        <Image
          src={blockQuoteIcon.src}
          alt="Block Quote"
          height={blockQuoteIcon.height}
          width={blockQuoteIcon.width}
        />
      </div>
      {content && (
        <div className={styles.block_quote__content} dangerouslySetInnerHTML={{ __html: content }}></div>
      )}
    </div>
  );
}
