import React from 'react';
import Link from 'next/link';
import styles from '../../styles/components/blocks/downloads.module.scss';
import type { DownloadItem } from '../../types/blocks/download-item';
import type { Attributes } from '../../types/blocks/attributes';

interface Props {
  downloads?: Array<DownloadItem>;
  attributes?: Attributes;
}

const Downloads: React.FC<Props> = ({ downloads = [], attributes }) => {
  const renderDownload = (item: DownloadItem, index: number): JSX.Element => (
    <Link key={index} href={item.download.mediaItemUrl} className={`${styles.downloads__button} btn primary download`} target="_blank" rel="noopener noreferrer">
      <span>{item.buttonText}</span>
    </Link>
  );

  return (
    <div id={attributes?.anchor} className="container">
      <div className={styles.downloads__wrapper}>
        <h3 className={styles.downloads__title}>Downloads</h3>

        <div className={styles.downloads__button_wrapper}>{downloads?.map(renderDownload)}</div>
      </div>
    </div>
  );
};

export default Downloads;
