import styles from '../../styles/components/blocks/hero-banner.module.scss';
import type { Attributes } from '../../types/blocks/attributes';
import Slider from 'react-slick';
import { useEffect, useRef, useState } from 'react';

interface Props {
  slides?: any;
  title?: string;
  autoSlide?: boolean;
  autoSlideDuration?: number;
  attributes?: Attributes;
}

export default function HeroBanner({
  autoSlide,
  autoSlideDuration,
  slides,
}: Props): JSX.Element {
  const slideAnimationSpeed = 500;

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    swipe: false,
    arrows: false,
    speed: slideAnimationSpeed,
  };
  const [isMobileWindow, setIsMobileWindow] = useState(false);

  const timer = useRef({});
  const sliderRef = useRef<null | Slider>(null);
  const sliderRef2 = useRef<null | Slider>(null);

  const sliderLength = slides.length;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [autoSlideTimer, setAutoSlideTimer] = useState(
    autoSlideDuration ? autoSlideDuration : 0 + slideAnimationSpeed
  );
  const [timerTicker, setTimerTicker] = useState(false);
  const [sliding, setSliding] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;

  const onTouchStart = (e: any) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (sliderLength > 1) {
      if (isLeftSwipe || isRightSwipe)
        isLeftSwipe ? handleNextSlide() : handlePreviousSlide();
    }
  };

  const handleTimeout = () => {
    setSliding(true);
    setTimeout(() => setSliding(false), slideAnimationSpeed + 100);
  };

  const handleNextSlide = () => {
    if (!sliding) {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
      if (sliderRef2.current) {
        sliderRef2.current.slickNext();
      }

      if (currentSlide === sliderLength - 1) {
        setCurrentSlide(0);
      } else {
        setCurrentSlide(currentSlide + 1);
      }
      handleTimeout();
    }
  };

  const handlePreviousSlide = () => {
    if (!sliding) {
      if (sliderRef.current) {
        sliderRef.current.slickPrev();
      }
      if (sliderRef2.current) {
        sliderRef2.current.slickPrev();
      }

      if (currentSlide === 0) {
        setCurrentSlide(sliderLength - 1);
      } else {
        setCurrentSlide(currentSlide - 1);
      }
      handleTimeout();
    }
  };

  const handleGoTo = (i: any) => {
    if (!sliding) {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(i);
      }
      if (sliderRef2.current) {
        sliderRef2.current.slickGoTo(i);
      }
      setCurrentSlide(i);
    }
    handleTimeout();
  };

  // check window size to disable auto swipe on mobile screen size
  useEffect(() => {

    function handleResize() {
      if (window.innerWidth < 787) {
        setIsMobileWindow(true);
      } else {
        setIsMobileWindow(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileWindow]);

  useEffect(() => {
    if (autoSlide && !isMobileWindow) {
      const NextSlide = () => {
        if (!sliding) {
          if (sliderRef.current) {
            sliderRef.current.slickNext();
          }
          if (sliderRef2.current) {
            sliderRef2.current.slickNext();
          }

          if (currentSlide === sliderLength - 1) {
            setCurrentSlide(0);
          } else {
            setCurrentSlide(currentSlide + 1);
          }
          handleTimeout();
        }
      };

      if (timerTicker) {
        setTimerTicker(false);
      }

      if (timerTicker) {
        NextSlide();
      }

      const countdownTimer = () => {
        setTimerTicker(true);
      };

      const autoSlide = () => {
        if (Number(timer.current) > 0) {
          clearTimeout(Number(timer.current));
        }
        setAutoSlideTimer(autoSlideDuration ? autoSlideDuration : 0);
        timer.current = setTimeout(
          countdownTimer,
          autoSlideTimer + slideAnimationSpeed
        );
      };

      if (!timerTicker) {
        autoSlide();
      }

      if (sliding) {
        autoSlide();
      }
    }
  }, [
    autoSlideTimer,
    autoSlideDuration,
    timerTicker,
    sliding,
    autoSlide,
    currentSlide,
    sliderLength,
    isMobileWindow
  ]);

  return (
    <>
      <div className={styles.hero_banner_wrapper}>
        <div className={`${styles.carousel_placement}`}>
          <Slider
            {...settings}
            ref={sliderRef}
            className={`${styles.carousel_image}`}
          >
            {slides.map((slide: any, i: any) => (
              <div className={styles.slide_wrapper} key={i}>
                <div className={styles.slide_container}>
                  <img
                    className={styles.slide_image}
                    src={slide.backgroundImage.sourceUrl}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className={styles.slide_overlay}></div>
        <div
          className={`${styles.carousel_placement} ${styles.carousel_content_wrapper}`}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <Slider
            ref={sliderRef2}
            {...settings}
            className={`${styles.carousel_placement} ${styles.carousel_content}`}
          >
            {slides.map((slide: any, i: any) => (
              <div className={styles.slide_wrapper} key={i}>
                <div className={styles.slide_container}>
                  <div className={styles.slide_content_flexbox}>
                    <div className={styles.slide_content}>
                      <h1>{slide.heading}</h1>
                      <p>{slide.text}</p>

                      <div className={`${styles.button_group} ${slide.buttonSolid && slide.buttonTransparent ? styles.button_group_full : null}`}>
                        {slide.buttonSolid && slide.buttonSolid && (
                          <div className={styles.button_wrapper}>
                            <a
                              href={slide.buttonSolid.url}
                              target={slide.buttonSolid.target}
                              className={`${styles.button} btn`}
                            >
                              {slide.buttonSolid.title}
                            </a>
                          </div>
                        )}
                        {slide.buttonTransparent && slide.buttonTransparent && (
                          <div className={styles.button_wrapper}>
                            <a
                              href={slide.buttonTransparent.url}
                              target={slide.buttonTransparent.target}
                              className={`${styles.button} ${styles.ghost_button} btn`}
                            >
                              {slide.buttonTransparent.title}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* controls */}
      {sliderLength > 1 ? (
        <div
          className={styles.slider_control_group}
          style={{
            opacity: sliding ? '0.8' : '1',
          }}
        >
          {/* prev button */}
          <div
            className={`${styles.arrow} ${styles.prev}`}
            onClick={handlePreviousSlide}
          >
            <div className={styles.arrow_top_line_wrapper}>
              <div className={styles.line}></div>
            </div>
            <div className={styles.arrow_bottom_line_wrapper}>
              <div className={styles.line}></div>
            </div>
          </div>
          <div className={styles.slider_dots}>
            {[...Array(sliderLength)].map((e, i) => (
              <div
                onClick={() => handleGoTo(i)}
                className={styles.slider_dot_wrapper}
                key={i}
              >
                <div
                  style={{
                    backgroundColor:
                      currentSlide === i ? '#1B1C1D' : 'transparent',
                  }}
                  className={styles.slider_dot}
                  key={i}
                ></div>
              </div>
            ))}
          </div>
          {/* next button */}
          <div className={styles.arrow} onClick={handleNextSlide}>
            <div className={styles.arrow_top_line_wrapper}>
              <div className={styles.line}></div>
            </div>
            <div className={styles.arrow_bottom_line_wrapper}>
              <div className={styles.line}></div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
