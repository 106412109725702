import styles from '../../styles/components/blocks/morgan-poll-chart.module.scss';
import type { Attributes } from '../../types/blocks/attributes';
import { Chart, ArcElement, BarElement, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
Chart.register(
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale
);

interface Props {
  title?: string;
  col1GraphText1?: string;
  col1GraphText2?: string;
  col1GraphTitle1?: string;
  col1GraphTitle1Color?: string;
  col1GraphTitle2Color?: string;
  col1GraphTitle2?: string;
  col1GraphValue1?: string;
  col1GraphValue2?: string;
  col1SubTitle?: string;
  col1Title?: string;
  col2Title?: string;
  col2SubTitle?: string;
  col2GraphValue4?: string;
  col2GraphValue3?: string;
  col2GraphValue2?: string;
  col2GraphValue1?: string;
  col2GraphTitle4Color?: string;
  col2GraphTitle4?: string;
  col2GraphTitle3Color?: string;
  col2GraphTitle3?: string;
  col2GraphTitle2Color?: string;
  col2GraphTitle2?: string;
  col2GraphTitle1Color?: string;
  col2GraphTitle1?: string;
  col3GraphBottomLineChartValues?: {
    chartValue1: number;
    chartValue2: number;
    chartValue3: number;
    chartValue4: number;
    chartValue5: number;
    chartValue6: number;
    chartValue7: number;
  };
  col3GraphBottomSubTitle?: string;
  col3GraphBottomTitle?: string;
  col3GraphBottomValue1?: string;
  col3GraphBottomValue2?: string;
  col3GraphBottomValue2Color?: string;
  col3GraphTopLineChartValues?: {
    chartValue1: number;
    chartValue2: number;
    chartValue3: number;
    chartValue4: number;
    chartValue5: number;
    chartValue6: number;
    chartValue7: number;
  };
  col3GraphTopSubTitle?: string;
  col3GraphTopTitle?: string;
  col3GraphTopValue1?: string;
  col3GraphTopValue2?: string;
  col3GraphTopValue2Color?: string;
  col4GraphBottomLineChartValues?: {
    chartValue1: number;
    chartValue2: number;
    chartValue3: number;
    chartValue4: number;
    chartValue5: number;
    chartValue6: number;
    chartValue7: number;
  };
  col4GraphBottomValue2Color?: string;
  col4GraphBottomValue2?: string;
  col4GraphBottomValue1?: string;
  col4GraphBottomTitle?: string;
  col4GraphBottomSubTitle?: string;
  col4GraphTopLineChartValues?: {
    chartValue1: number;
    chartValue2: number;
    chartValue3: number;
    chartValue4: number;
    chartValue5: number;
    chartValue6: number;
    chartValue7: number;
  };
  col4GraphTopSubTitle?: string;
  col4GraphTopTitle?: string;
  col4GraphTopValue1?: string;
  col4GraphTopValue2?: string;
  col4GraphTopValue2Color?: string;
  attributes?: Attributes;
}

export default function MorganPollChart({
  title,
  col1GraphText1,
  col1GraphText2,
  col1GraphTitle1,
  col1GraphTitle1Color,
  col1GraphTitle2Color,
  col1GraphTitle2,
  col1GraphValue1,
  col1GraphValue2,
  col1SubTitle,
  col1Title,
  col2Title,
  col2SubTitle,
  col2GraphValue4,
  col2GraphValue3,
  col2GraphValue2,
  col2GraphValue1,
  col2GraphTitle4Color,
  col2GraphTitle4,
  col2GraphTitle3Color,
  col2GraphTitle3,
  col2GraphTitle2Color,
  col2GraphTitle2,
  col2GraphTitle1Color,
  col2GraphTitle1,
  col3GraphBottomLineChartValues,
  col3GraphBottomSubTitle,
  col3GraphBottomTitle,
  col3GraphBottomValue1,
  col3GraphBottomValue2,
  col3GraphBottomValue2Color,
  col3GraphTopLineChartValues,
  col3GraphTopSubTitle,
  col3GraphTopTitle,
  col3GraphTopValue1,
  col3GraphTopValue2,
  col3GraphTopValue2Color,
  col4GraphBottomLineChartValues,
  col4GraphBottomValue2Color,
  col4GraphBottomValue2,
  col4GraphBottomValue1,
  col4GraphBottomTitle,
  col4GraphBottomSubTitle,
  col4GraphTopLineChartValues,
  col4GraphTopSubTitle,
  col4GraphTopTitle,
  col4GraphTopValue1,
  col4GraphTopValue2,
  col4GraphTopValue2Color,
  attributes,
}: Props): JSX.Element {
  if (typeof window !== 'undefined') {
    window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
  }

  /* Doughnut Chart data and options */
  const doughnutData = {
    datasets: [
      {
        data: [col1GraphValue1, col1GraphValue2],
        backgroundColor: [col1GraphTitle1Color, col1GraphTitle2Color],
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    rotation: 270,
    circumference: 180
  };

  /* Bar Chart data and options */
  const barData = {
    labels: [
      col2GraphTitle1,
      col2GraphTitle2,
      col2GraphTitle3,
      col2GraphTitle4,
    ],
    datasets: [
      {
        label: col2Title,
        data: [
          col2GraphValue1,
          col2GraphValue2,
          col2GraphValue3,
          col2GraphValue4,
        ],
        backgroundColor: [
          col2GraphTitle1Color,
          col2GraphTitle2Color,
          col2GraphTitle3Color,
          col2GraphTitle4Color,
        ],
        borderColor: [
          col2GraphTitle1Color,
          col2GraphTitle2Color,
          col2GraphTitle3Color,
          col2GraphTitle4Color,
        ],
        borderWidth: 1,
        borderRadius: 5,
        barPercentage: 1,
        categoryPercentage: 0.8,
      },
    ],
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
    },
  };

  /* Line chart col 3 top data and options */
  const lineDataCol3Top = {
    labels: ['1', '2', '3', '4', '5', '6', '7'],
    datasets: [
      {
        data: [
          col3GraphTopLineChartValues?.chartValue1,
          col3GraphTopLineChartValues?.chartValue2,
          col3GraphTopLineChartValues?.chartValue3,
          col3GraphTopLineChartValues?.chartValue4,
          col3GraphTopLineChartValues?.chartValue5,
          col3GraphTopLineChartValues?.chartValue6,
          col3GraphTopLineChartValues?.chartValue7,
        ],
      },
    ],
  };

  const lineOptionsCol3Top = {
    responsive: true,
    lineTension: 0.1,
    borderColor: col1GraphTitle1Color,
    borderCapStyle: 'round',
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: col1GraphTitle1Color,
    pointBackgroundColor: '#fff',
    pointBorderWidth: 6,
    pointHoverRadius: 5,
    pointRadius: 2,
    pointHitRadius: 6,
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
    },
  };

  /* Line chart col 3 bottom data and options */
  const lineDataCol3Bottom = {
    labels: ['1', '2', '3', '4', '5', '6', '7'],
    datasets: [
      {
        data: [
          col3GraphBottomLineChartValues?.chartValue1,
          col3GraphBottomLineChartValues?.chartValue2,
          col3GraphBottomLineChartValues?.chartValue3,
          col3GraphBottomLineChartValues?.chartValue4,
          col3GraphBottomLineChartValues?.chartValue5,
          col3GraphBottomLineChartValues?.chartValue6,
          col3GraphBottomLineChartValues?.chartValue7,
        ],
      },
    ],
  };

  const lineOptionsCol3Bottom = {
    responsive: true,
    lineTension: 0.1,
    borderColor: col1GraphTitle1Color,
    borderCapStyle: 'round',
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: col1GraphTitle1Color,
    pointBackgroundColor: '#fff',
    pointBorderWidth: 6,
    pointHoverRadius: 5,
    pointRadius: 2,
    pointHitRadius: 6,
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
    },
  };

  /* Line chart col 4 top data and options */
  const lineDataCol4Top = {
    labels: ['1', '2', '3', '4', '5', '6', '7'],
    datasets: [
      {
        data: [
          col4GraphTopLineChartValues?.chartValue1,
          col4GraphTopLineChartValues?.chartValue2,
          col4GraphTopLineChartValues?.chartValue3,
          col4GraphTopLineChartValues?.chartValue4,
          col4GraphTopLineChartValues?.chartValue5,
          col4GraphTopLineChartValues?.chartValue6,
          col4GraphTopLineChartValues?.chartValue7,
        ],
      },
    ],
  };

  const lineOptionsCol4Top = {
    responsive: true,
    lineTension: 0.1,
    borderColor: col1GraphTitle1Color,
    borderCapStyle: 'round',
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: col1GraphTitle1Color,
    pointBackgroundColor: '#fff',
    pointBorderWidth: 6,
    pointHoverRadius: 5,
    pointRadius: 2,
    pointHitRadius: 6,
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
    },
  };

  /* Line chart col 4 bottom data and options */
  const lineDataCol4Bottom = {
    labels: ['1', '2', '3', '4', '5', '6', '7'],
    datasets: [
      {
        data: [
          col4GraphBottomLineChartValues?.chartValue1,
          col4GraphBottomLineChartValues?.chartValue2,
          col4GraphBottomLineChartValues?.chartValue3,
          col4GraphBottomLineChartValues?.chartValue4,
          col4GraphBottomLineChartValues?.chartValue5,
          col4GraphBottomLineChartValues?.chartValue6,
          col4GraphBottomLineChartValues?.chartValue7,
        ],
      },
    ],
  };

  const lineOptionsCol4Bottom = {
    responsive: true,
    lineTension: 0.1,
    borderColor: col1GraphTitle1Color,
    borderCapStyle: 'round',
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: col1GraphTitle1Color,
    pointBackgroundColor: '#fff',
    pointBorderWidth: 6,
    pointHoverRadius: 5,
    pointRadius: 2,
    pointHitRadius: 6,
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
    },
  };

  return (
    <div id={attributes?.anchor} className={styles.morgan_poll_chart__outer_wrapper}>
      <div className="container">
        <div className={styles.morgan_poll_chart__wrapper}>
          <div className={styles.morgan_poll_chart__header}>
            <h2>{title}</h2>
          </div>

          {/* 4 Columns container */}
          <div className={styles.morgan_poll_chart__graph_wrapper}>
            {/* Column 1 */}
            <div className={`${styles.morgan_poll_chart__col_wrapper} ${styles.morgan_poll_chart__col_one}`}>
              <h3 className={styles.morgan_poll_chart__graph_title}>{col1Title}</h3>
              <span className={styles.morgan_poll_chart__graph_sub_title}>{col1SubTitle}</span>

              <div className={styles.morgan_poll_chart__description_wrapper}>
                <div className={`${styles.morgan_poll_chart__description_col} ${styles.morgan_poll_chart__left_align}`}>
                  <div className={styles.morgan_poll_chart__description_col_header} style={{ color: col1GraphTitle1Color }}>
                    {col1GraphTitle1}
                  </div>

                  <div className={styles.morgan_poll_chart__description_col_value}>
                    {col1GraphValue1}%
                  </div>

                  <div className={styles.morgan_poll_chart__description_col_text}>
                    {col1GraphText1}
                  </div>
                </div>

                <div className={`${styles.morgan_poll_chart__description_col} ${styles.morgan_poll_chart__right_align}`}>
                  <div className={styles.morgan_poll_chart__description_col_header} style={{ color: col1GraphTitle2Color }}>
                    {col1GraphTitle2}
                  </div>

                  <div className={styles.morgan_poll_chart__description_col_value}>
                    {col1GraphValue2}%
                  </div>

                  <div className={styles.morgan_poll_chart__description_col_text}>
                    {col1GraphText2}
                  </div>
                </div>
              </div>

              <div className={styles.image_wrapper}>
                <Doughnut
                  data={doughnutData}
                  width={50}
                  height={25}
                  options={doughnutOptions}
                />
              </div>
            </div>

            {/* Column 2 */}
            <div className={`${styles.morgan_poll_chart__col_wrapper} ${styles.morgan_poll_chart__col_two}`}>
              <h3 className={styles.morgan_poll_chart__graph_title}>
                {col2Title}
              </h3>

              <span className={styles.morgan_poll_chart__graph_sub_title}>
                {col2SubTitle}
              </span>

              <div className={styles.morgan_poll_chart__description_wrapper}>
                <div className={styles.morgan_poll_chart__description_col}>
                  <div style={{ color: col2GraphTitle1Color }}>
                    {col2GraphTitle1}
                  </div>

                  <div className={styles.morgan_poll_chart__description_col_value}>
                    {col2GraphValue1}
                  </div>
                </div>

                <div className={styles.morgan_poll_chart__description_col}>
                  <div style={{ color: col2GraphTitle2Color }}>
                    {col2GraphTitle2}
                  </div>

                  <div className={styles.morgan_poll_chart__description_col_value}>
                    {col2GraphValue2}
                  </div>
                </div>

                <div className={styles.morgan_poll_chart__description_col}>
                  <div style={{ color: col2GraphTitle3Color }}>
                    {col2GraphTitle3}
                  </div>

                  <div className={styles.morgan_poll_chart__description_col_value}>
                    {col2GraphValue3}
                  </div>
                </div>

                <div className={styles.morgan_poll_chart__description_col}>
                  <div style={{ color: col2GraphTitle4Color }}>
                    {col2GraphTitle4}
                  </div>

                  <div className={styles.morgan_poll_chart__description_col_value}>
                    {col2GraphValue4}
                  </div>
                </div>
              </div>

              <div className={styles.image_wrapper}>
                <Bar
                  data={barData}
                  width={375}
                  height={200}
                  options={barOptions}
                />
              </div>
            </div>

            {/* Column 3 */}
            <div className={`${styles.morgan_poll_chart__col_wrapper} ${styles.morgan_poll_chart__col_three} ${styles.stacked}`}>
              <div className={`${styles.morgan_poll_chart__top} ${styles.morgan_poll_chart__col_top}`}>
                <h3 className={styles.morgan_poll_chart__graph_title}>
                  {col3GraphTopTitle}
                </h3>

                <span className={styles.morgan_poll_chart__graph_sub_title}>
                  {col3GraphTopSubTitle}
                </span>

                <div className={`${styles.morgan_poll_chart__graph_wrapper__inner}`}>
                  <div className={styles.morgan_poll_chart__image_wrapper}>
                    <Line
                      data={lineDataCol3Top}
                      width={100}
                      height={35}
                      options={lineOptionsCol3Top}
                    />
                  </div>

                  <div className={styles.morgan_poll_chart__stats_wrapper}>
                    <div className={styles.morgan_poll_chart__stats_top_number}>
                      {col3GraphTopValue1}
                    </div>

                    <div className={styles.morgan_poll_chart__stats_bottom_number} style={{ color: col3GraphTopValue2Color }}>
                      {col3GraphTopValue2}
                    </div>
                  </div>
                </div>
              </div>

              {/* Column 3 bottom chart */}
              <div className={styles.morgan_poll_chart__bottom}>
                <h3 className={styles.morgan_poll_chart__graph_title}>
                  {col3GraphBottomTitle}
                </h3>

                <span className={styles.morgan_poll_chart__graph_sub_title}>
                  {col3GraphBottomSubTitle}
                </span>

                <div className={`${styles.morgan_poll_chart__graph_wrapper__inner}`}>
                  <div className={styles.morgan_poll_chart__image_wrapper}>
                    <Line
                      data={lineDataCol3Bottom}
                      width={100}
                      height={35}
                      options={lineOptionsCol3Bottom}
                    />
                  </div>

                  <div className={styles.morgan_poll_chart__stats_wrapper}>
                    <div className={styles.morgan_poll_chart__stats_top_number}>
                      {col3GraphBottomValue1}
                    </div>

                    <div className={styles.morgan_poll_chart__stats_bottom_number} style={{ color: col3GraphBottomValue2Color }}>
                      {col3GraphBottomValue2}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Column 4 */}
            <div className={`${styles.morgan_poll_chart__col_wrapper} ${styles.morgan_poll_chart__col_four} ${styles.stacked}`}>
              <div className={`${styles.morgan_poll_chart__top} ${styles.morgan_poll_chart__col_top}`}>
                <h3 className={styles.morgan_poll_chart__graph_title}>
                  {col4GraphTopTitle}
                </h3>

                <span className={styles.morgan_poll_chart__graph_sub_title}>
                  {col4GraphTopSubTitle}
                </span>

                <div className={`${styles.morgan_poll_chart__graph_wrapper__inner}`}>
                  <div className={styles.morgan_poll_chart__image_wrapper}>
                    <Line
                      data={lineDataCol4Top}
                      width={100}
                      height={35}
                      options={lineOptionsCol4Top}
                    />
                  </div>

                  <div className={styles.morgan_poll_chart__stats_wrapper}>
                    <div className={styles.morgan_poll_chart__stats_top_number}>
                      {col4GraphTopValue1}
                    </div>

                    <div className={styles.morgan_poll_chart__stats_bottom_number} style={{ color: col4GraphTopValue2Color }}>
                      {col4GraphTopValue2}
                    </div>
                  </div>
                </div>
              </div>

              {/* Column 4 bottom chart */}
              <div className={styles.morgan_poll_chart__bottom}>
                <h3 className={styles.morgan_poll_chart__graph_title}>
                  {col4GraphBottomTitle}
                </h3>

                <span className={styles.morgan_poll_chart__graph_sub_title}>
                  {col4GraphBottomSubTitle}
                </span>

                <div className={`${styles.morgan_poll_chart__graph_wrapper__inner}`}>
                  <div className={styles.morgan_poll_chart__image_wrapper}>
                    <Line
                      data={lineDataCol4Bottom}
                      width={100}
                      height={35}
                      options={lineOptionsCol4Bottom}
                    />
                  </div>

                  <div className={styles.morgan_poll_chart__stats_wrapper}>
                    <div className={styles.morgan_poll_chart__stats_top_number}>
                      {col4GraphBottomValue1}
                    </div>

                    <div className={styles.morgan_poll_chart__stats_bottom_number} style={{ color: col4GraphBottomValue2Color }}>
                      {col4GraphBottomValue2}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
