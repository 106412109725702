import styles from '../../styles/components/blocks/full-width-callout.module.scss';
import type { Attributes } from '../../types/blocks/attributes';
import { useState } from 'react';
import phoneIcon from '../../public/images/icons/phone.svg';
import headphonesIcon from '../../public/images/icons/headphones.svg';
import closeIcon from '../../public/images/icons/close.svg';
import GravityForm from '../gravity-form';
import { getPageForm } from '../../lib/queries/getPageForm';
import { useEffect } from 'react';


interface Props {
  title?: string;
  content?: string;
  show_icon?: boolean;
  button_text?: string;
  button_link?: string;
  attributes?: Attributes;
}

export default function FullWidthCallout({
  title,
  content,
  show_icon,
  button_text,
  button_link,
  attributes
}: Props): JSX.Element {
  return (
    <div id={attributes?.anchor} className="container">
	   	<div className={styles.full_width_callout__wrapper}>
				{show_icon && (
					<div className={styles.full_width_callout__headphone}>
						<img src={headphonesIcon.src} alt="Phone Icon" />
					</div>
				)}
				<div className={styles.full_width_callout__content_wrapper}>
					{title && (
						<h3 className={styles.full_width_callout__title}>
							{title}
						</h3>
					)}
					{content && (
						<div className={styles.full_width_callout__content}>
							{content}
						</div>
					)}
				</div>
				{button_text && button_link && (
					<a href={button_link} className={`${styles.full_width_callout__button} btn btn-prmary`}>
				    	{button_text}
				  </a>
				)}         
			</div>
    </div>      
  );
}
