import styles from '../../styles/components/blocks/block-quote.module.scss';
import type { Attributes } from '../../types/blocks/attributes';
import blockQuoteIcon from '../../public/images/icons/block-quote.svg';
import type { Image as ImageType } from '../../types/blocks/image';
import FullWidthTile from '../full-width-tile';

interface Props {
  title?: string;
  linkText?: string;
  content?: string;
  link?: string;
  image_right?: boolean;
  tileImage?: ImageType;
  attributes?: Attributes;
}

export default function FullWidthTileWrapper({
  title,
  linkText,
  content,
  link,
  tileImage,
  image_right,
  attributes,
}: Props): JSX.Element {
  return (
    <FullWidthTile
      title={title}
      tileImage={tileImage}
      slug={link}
      link_text={linkText}
      excerpt={content}
      attributes={attributes}
      image_right={image_right}
    />
  );
}
