import styles from '../../styles/components/blocks/abixNewsletter.module.scss';
import type { Attributes } from '../../types/blocks/attributes';
import { abixNewsletterApi } from '../../lib/apis/abixNewsletter';
import { useState } from 'react';

interface Props {
  title?: string;
  buttonText?: string;
  attributes?: Attributes;
  noTopPadding?: boolean;
}

export default function AbixNewsletter({
  title,
  buttonText,
  attributes,
}: Props): JSX.Element {
  const [displayError, setDisplayError] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function signupUser(event: any) {
    setDisplayError(false);
    setDisplaySuccess(false);
    event.preventDefault();

    const response = await abixNewsletterApi(event.target);
    if (!response.Code) {
      setErrorMessage('Signed up successfully');
      setDisplaySuccess(true);
    } else {
      setDisplayError(true);
      setErrorMessage(response.Message);
    }
  }
  return (
    <div id={attributes?.anchor} className={`${styles.newsletter__wrapper}`}>
      <div className="container">
        <div className={styles.newsletter__inner_wrapper}>
          {title ? (
            <div className={styles.newsletter__title}>
              <h2>{title}</h2>
            </div>
          ) : null}
          <div className={styles.newsletter__form_wrapper}>
            <form
              onSubmit={signupUser}
              className={`${displaySuccess ? styles.show_success : ''}`}
            >
              <div
                className={`${styles.newsletter__row} ${styles.newsletter__name_email_wrapper}`}
              >
                <div className={`${styles.newsletter__name}`}>
                  <label htmlFor="name">Name</label>
                  <input id="name" type="text" autoComplete="name" required />
                </div>
                <div className={`${styles.newsletter__email}`}>
                  <label htmlFor="name">Email</label>
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    required
                  />
                </div>
              </div>
              <button className="btn primary" type="submit">
                {buttonText ? buttonText : 'Subscribe'}
              </button>
            </form>
            {displayError && (
              <div className={styles.newsletter_error_message}>
                {errorMessage}
              </div>
            )}
            {displaySuccess && (
              <div className={styles.newsletter_success_message}>
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
