import styles from '../../styles/components/blocks/two-col-image-text.module.scss';
import type { Image as ImageType } from '../../types/blocks/image';
import type { Attributes } from '../../types/blocks/attributes';
import CickThroughLink from '../click-through-link';

interface Props {
  click_through_link: string;
  click_through_text: string;
  content: string;
  image?: ImageType;
  title: string;
  red_bar_top: boolean;
  image_right: boolean;
  attributes?: Attributes;

}
 
export default function TwoColImageText({
  image,
  click_through_text,
  click_through_link,
  content,
  title,
  red_bar_top,
  image_right,
  attributes
}: Props): JSX.Element {

  const ImageColumn = function(): JSX.Element {
    return (
      <div className={styles.img_col}>
        <img src={image?.sourceUrl} alt={title} />
      </div>
    )
  };

  const TextColumn = function(): JSX.Element {
    let style = image_right
      ? `${styles.text_col} ${styles.left_text}`
      : `${styles.text_col} ${styles.right_text}`;

    return (
      <div className={style}>
        <h2>{title}</h2>
        <div
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <CickThroughLink 
          text={click_through_text} 
          link={click_through_link} 
        />
      </div>
    );
  };

  const RedBar = function(): JSX.Element {
    let style = image_right 
      ? `${styles.red_bar} ${styles.left_bar}`
      : `${styles.red_bar} ${styles.right_bar}`;

    return (
      <div className={style} />
    );
  };

  return (
    <div id={attributes?.anchor} className="container">
        <div className={styles.inner}>
          { red_bar_top 
            ? <RedBar />
            : ''
          }
          { image_right 
            ? (
              <div className={styles.image_right_row}>
                <TextColumn />
                <ImageColumn />
              </div>
            )
            : (
              <div className={styles.image_left_row}>
                <ImageColumn />
                <TextColumn />
              </div>
            )
          }
        </div>
    </div>
  );
}
