import styles from '../../styles/components/blocks/promo-banner.module.scss';
import type { Attributes } from '../../types/blocks/attributes';
import type { Image as ImageType } from '../../types/blocks/image';

interface Props {
  background_image?: ImageType;
  background_image_mobile?: ImageType;
  button_type?: string;
  button_text?: string;
  button_link?: string;
  title?: string;
  attributes?: Attributes;
}

export default function PromoBanner({
  background_image,
  background_image_mobile,
  button_type,
  button_text,
  button_link,
  title,
  attributes
}: Props): JSX.Element {
  let image = {
    src: background_image?.sourceUrl,
    height: background_image?.mediaDetails?.height ? parseInt(background_image?.mediaDetails?.height) : 0,
    width: background_image?.mediaDetails?.width ? parseInt(background_image?.mediaDetails?.width) : 0,
  };
  let mobile_image = {
    src: background_image_mobile?.sourceUrl,
    height: background_image_mobile?.mediaDetails?.height ? parseInt(background_image_mobile?.mediaDetails?.height) : 0,
    width: background_image_mobile?.mediaDetails?.width ? parseInt(background_image_mobile?.mediaDetails?.width) : 0,
  };
  return (
    <div id={attributes?.anchor}>
      <div className={styles.promo_banner__wrapper}>
        <img
          src={image.src}
          alt="Banner"
          className={styles.promo_banner__desktop_image}
        />
        <img
          src={mobile_image.src}
          alt="Mobile Banner"
          className={styles.promo_banner__mobile_image}
        />
        <div className={`${styles.promo_banner__title_button_outer_wrapper}`}>
          <div className={`${styles.promo_banner__title_button_wrapper} container`}>
            {title && 
              <div className={styles.promo_banner__title}>{title}</div>
            }
            {button_text && button_link && (
              <a href={button_link} className={`${styles.promo_banner__button} btn btn-prmary`}>
                  {button_text}
              </a>
            )}  
          </div>
        </div>
      </div>
    </div>
  );
}
