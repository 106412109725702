import styles from '../../styles/components/blocks/slim-line-image-text.module.scss';
import type { Image as ImageType } from '../../types/blocks/image';
import type { Attributes } from '../../types/blocks/attributes';
import CickThroughLink from '../click-through-link';
import FullWidthTile from '../full-width-tile';

interface Props {
  click_through_link: string;
  click_through_text: string;
  click_through_is_button?: boolean;
  click_through_open_new_tab?: boolean;
  click_through_position_top?: boolean;
  content: string;
  image?: ImageType;
  title: string;
  image_right: boolean;
  attributes?: Attributes;
}
 
export default function SlimLineImageText({
  image,
  click_through_text,
  click_through_link,
  click_through_is_button = false,
  click_through_open_new_tab = false,
  click_through_position_top = false,
  content,
  title,
  image_right,
  attributes
}: Props): JSX.Element {

  return (
  <FullWidthTile
      title={title}
      tileImage={image}
      slug={click_through_link}
      link_text={click_through_text}
      excerpt={content}
      attributes={attributes}
      image_right={image_right}
      showLink={!!click_through_link}
      isButton={click_through_is_button}
      openInNewTab={click_through_open_new_tab}
      linkPositionTop={click_through_position_top}
    />
  );
}
