import styles from '../../styles/components/blocks/icon-title-text-list.module.scss';
import type { IconTitleTextListItem } from '../../types/blocks/icon-title-text-list-item';
import type { Attributes } from '../../types/blocks/attributes';

interface Props {
  items?: Array<IconTitleTextListItem>;
  attributes?: Attributes;
}

export default function IconTitleTextList({
  items,
  attributes
}: Props): JSX.Element {

  const IconTitleText = function(
    item: IconTitleTextListItem, 
    key: number): JSX.Element {
      return (
        <div key={key} className={styles.item}>
          {item?.icon?.sourceUrl &&(
            <img src={item?.icon?.sourceUrl} alt={item?.title} />
          )}
          <div className={styles.item_title}>
            {item?.title}
          </div>
          <div className={styles.item_content}>
            {item?.text}
          </div>
        </div>
     );
  };

  return (
    <div id={attributes?.anchor} className="container">
      <div className={styles.inner}>
        {items?.map(IconTitleText)}
      </div>
    </div>
  );
}
