import classNames from 'classnames';
import styles from '../../styles/components/blocks/gravity-form.module.scss';
import type { Attributes } from '../../types/blocks/attributes';
import { useState } from 'react';
import { useEffect } from 'react';
import { getPageForm } from '../../lib/queries/getPageForm';
import Form from '../gravity-form';
import { GravityForm as FormType } from '../../types/api';

interface Props {
  formId?: string;
  attributes?: Attributes;
  noMargins?: boolean;
}

export default function GravityForm({ formId, attributes, noMargins }: Props): JSX.Element {
  const [form, setForm] = useState<FormType>();
  const [hasLoaded, setHasLoaded] = useState(false);

  const getForm = async (formId?: string) => {
    if (formId && !hasLoaded) {
      const formResponse = await getPageForm(formId);
      setForm(formResponse.form);
      setHasLoaded(true);
    }
  };

  useEffect(() => {
    getForm(formId);
  });

  return (
    <div
      id={attributes?.anchor}
      className={classNames(styles.gravity_form_wrapper, {
        [styles.no_margins]: noMargins,
      })}
    >
      {form?.title && <h3 className={styles.form_title}>{form.title}</h3>}
      {form && <Form gravityForm={form} hideTitle={true} />}
    </div>
  );
}
