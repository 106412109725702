import styles from '../../styles/components/blocks/content-highlight-tiles.module.scss';
import type { Attributes } from '../../types/blocks/attributes';
import type { ContentHighlightTile } from '../../types/api';
import { useState } from 'react';
import { getPageForm } from '../../lib/queries/getPageForm';
import { useEffect } from 'react';

import RelatedProduct from '../related-product';

interface Props {
  tiles?: Array<ContentHighlightTile>;  
  title?: string;
  view_all_link?: string;
  view_all_text?: string;
  attributes?: Attributes;
}

export default function ContentHighlightTiles({
	tiles,
	title,
	view_all_link,
	view_all_text,
  attributes
}: Props): JSX.Element {
  return (
    <div id={attributes?.anchor} className={`${styles.content_highlight_tiles__wrapper} container`}>
      <div className={styles.content_highlight_tiles__header_wrapper}>
        <h2>{title}</h2>
        {view_all_link && view_all_text && (
          <a href={view_all_link} className={`${styles.full_width_callout__button} btn btn-prmary`}>
              {view_all_text}
          </a>
        )} 
      </div>
      <div className={styles.content_highlight_tiles__tile_wrapper}>
        {tiles && tiles.map(function (tile, i) {                    
          return (
            <RelatedProduct
              key={i}
              image={tile.image?.sourceUrl}
              title={tile.title}
              excerpt={tile.excerpt}
              slug={tile.link}
              category={tile.category}
              prefixSlug=""
            />);
        })}
      </div>
    </div>      
  );
}
