import type { NewsletterFormProperties } from '../../types/apis/newsletter';

export async function abixNewsletterApi(form: NewsletterFormProperties) {
  let name = form.name.value;
  let email = form.email.value;

  const data = {
    "Name": name,
    "EmailAddress": email,
    "Resubscribe": true,
    "RestartSubscriptionBasedAutoresponders": true,
    "ConsentToTrack":"Yes"
  };
  const headers = { 'Content-Type': 'application/json' };

  const res = await fetch(`/api/newsletter`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });

  var json = await res.json();

  if (!json.Code){
    const abixData = {
      "Name": name,
      "EmailAddress": email,
      "Resubscribe": true,
      "RestartSubscriptionBasedAutoresponders": true,
      "ConsentToTrack":"Yes"
    };
    const abixres = await fetch(`/api/abixnewsletter`, {
      method: 'POST',
      headers,
      body: JSON.stringify(abixData),
    });
    json = await abixres.json();
  }
  return json;
}
