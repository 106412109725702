import { useEffect, useState } from 'react';
import styles from '../../styles/components/blocks/offices.module.scss';
import type { officesGroup } from '../../types/blocks/offices';
import type { Attributes } from '../../types/blocks/attributes';
import parse from 'html-react-parser';
import Collapsible from 'react-collapsible';
import { convertTitleToAnchor } from '../../lib/side-menu-links';
import { useRouter } from 'next/router';

interface Props {
  offices?: Array<officesGroup>;
  attributes?: Attributes;
}

export default function Offices({ offices, attributes }: Props): JSX.Element {
    const [hashes, setHashes] = useState<Array<string | undefined>>([]);
    const [officesOpen, setOfficesOpen] = useState<Array<boolean>>([]);
    const router = useRouter();

    useEffect(() => {
        const onHashChangeComplete = () => {
            const hashValue = location.hash.replace(/^#/, '');
            const index = hashes.findIndex((hash) => hash === hashValue);
            if (index >= 0) {
                const newOfficesOpen = [...officesOpen];
                newOfficesOpen[index] = true;
                setOfficesOpen(newOfficesOpen);
            }
        };
        router.events.on('hashChangeComplete', onHashChangeComplete);

        return () => {
            router.events.off('hashChangeComplete', onHashChangeComplete);
        };
    }, [router.events, hashes, officesOpen]);

    useEffect(() => {
        setHashes((offices || []).map((office) => {
            return office.officesGroupSidebarTitle ? convertTitleToAnchor(office.officesGroupSidebarTitle) : undefined;
        }));
        if (offices?.length != officesOpen.length) {
            setOfficesOpen(new Array(offices?.length || 0).fill(false));
        }
    }, [offices]);

  return (
        <div id={attributes?.anchor} className={`${styles.offices__wrapper}`}>
            <h2>Offices</h2>

            <div className={styles.offices_accordion__wrapper}>
                {offices?.map((officesGroup, index) => {
                    return (
                        <div
                            id={hashes[index]}
                            key={index}
                        >
                            <Collapsible
                            triggerTagName="button"
                            classParentString={`${styles.offices_accordion__item}`}
                            contentInnerClassName={styles.offices_group__wrapper}
                            transitionTime={200}
                            open={officesOpen[index]}
                            trigger={
                                <div>
                                    {officesGroup.officesGroupTitle}
                                    <span className={styles.open_icon}></span>
                                </div>
                            }
                            triggerWhenOpen={
                                <div>
                                {officesGroup.officesGroupTitle}
                                <span className={styles.close_icon}></span>
                                </div>
                            }
                            onClose={() => {
                                officesOpen[index] = false;
                                setOfficesOpen(officesOpen);
                            }}
                            onOpen={() => {
                                officesOpen[index] = true;
                                setOfficesOpen(officesOpen);
                            }}
                            >
                            {officesGroup && officesGroup.offices && officesGroup.offices.map((office, index: number) => (
                                <div key={index} className={styles.office__wrapper}>
                                    <h4 className={styles.office__heading}>
                                        {office.officeTitle}
                                    </h4>

                                    <div className={styles.office__information}>
                                        {parse(office.officeInformation)}
                                    </div>
                                </div>
                            ))}
                            </Collapsible>
                        </div>
                    );
                })}
            </div>
        </div>
  );
}
